const TelegramIcon = () => (
  <svg width="50" height="49" viewBox="0 0 50 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.0298 48.9778C38.4178 48.9778 49.271 38.1246 49.271 24.7365C49.271 11.3484 38.4178 0.495239 25.0298 0.495239C11.6417 0.495239 0.788498 11.3484 0.788498 24.7365C0.788498 38.1246 11.6417 48.9778 25.0298 48.9778Z"
      fill="#009393"
    />
    <path
      d="M36.3396 15.4841C36.2364 16.0158 36.1362 16.5485 36.029 17.0794C35.5276 19.564 35.0249 22.0483 34.5208 24.5324C33.9291 27.454 33.3376 30.3754 32.7463 33.2964C32.652 33.7625 32.559 34.2291 32.4643 34.6956C32.454 34.7461 32.4402 34.7966 32.4268 34.8467C32.0831 36.139 31.1179 36.2842 30.17 35.609C28.6306 34.5124 27.1246 33.3693 25.6043 32.2458C25.2272 31.967 24.8446 31.694 24.4737 31.4084C24.3459 31.3101 24.2686 31.3248 24.1551 31.4357C23.276 32.2934 22.3921 33.1463 21.5034 33.9945C21.3059 34.1864 21.0909 34.3595 20.8612 34.5115C20.3697 34.8301 19.8187 34.7019 19.5577 34.179C19.3597 33.7826 19.2185 33.3554 19.0795 32.9327C18.4453 31.0049 17.8173 29.075 17.1955 27.143C17.1401 26.971 17.0547 26.8784 16.876 26.8235C15.0858 26.2725 13.301 25.7099 11.5104 25.1549C11.1927 25.0565 10.9178 24.91 10.7686 24.5958C10.5576 24.1525 10.7449 23.6265 11.2449 23.3499C11.5158 23.2002 11.8169 23.1033 12.1083 22.9924C16.9256 21.1671 21.7435 19.3427 26.562 17.5191C29.1902 16.5241 31.8192 15.5308 34.4489 14.5394C34.717 14.4384 35.0003 14.3383 35.2818 14.32C35.8824 14.2806 36.2784 14.6743 36.307 15.2767C36.3105 15.3437 36.307 15.4108 36.307 15.4778L36.3396 15.4841ZM20.2597 33.0587L20.2923 33.0542C20.4532 31.643 20.619 30.2322 20.7714 28.8206C20.8013 28.5435 20.8813 28.3317 21.1034 28.1328C24.5869 25.0139 28.0645 21.8887 31.5361 18.757C31.5911 18.7074 31.6724 18.6613 31.6894 18.5997C31.7243 18.4732 31.7238 18.3369 31.7381 18.2051C31.6201 18.1952 31.5004 18.1702 31.3847 18.1818C31.3091 18.1894 31.2358 18.2457 31.1666 18.2886C30.8311 18.4972 30.4966 18.7057 30.1629 18.9143L19.4607 25.6299C19.0138 25.9101 18.567 26.1916 18.1134 26.4789L20.2597 33.0587Z"
      fill="white"
    />
  </svg>
)
export default TelegramIcon
