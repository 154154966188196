const YoutubeIcon = () => (
  <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.3539 50.5941C39.0823 50.5941 50.2115 39.465 50.2115 25.7365C50.2115 12.0081 39.0823 0.878937 25.3539 0.878937C11.6254 0.878937 0.496323 12.0081 0.496323 25.7365C0.496323 39.465 11.6254 50.5941 25.3539 50.5941Z"
      fill="#009393"
    />
    <path
      d="M37.5171 18.8002C37.3737 18.26 37.0908 17.7669 36.6969 17.3705C36.3029 16.974 35.8117 16.688 35.2725 16.5411C33.2927 16.007 25.3539 16.007 25.3539 16.007C25.3539 16.007 17.4151 16.007 15.4353 16.5411C14.8961 16.688 14.4049 16.974 14.0109 17.3705C13.617 17.7669 13.3341 18.26 13.1907 18.8002C12.6601 20.7926 12.6601 24.9503 12.6601 24.9503C12.6601 24.9503 12.6601 29.108 13.1907 31.1008C13.3341 31.641 13.617 32.1341 14.0109 32.5305C14.4049 32.927 14.8961 33.213 15.4353 33.3599C17.4151 33.8936 25.3539 33.8936 25.3539 33.8936C25.3539 33.8936 33.2927 33.8936 35.2725 33.3599C35.8117 33.213 36.3029 32.927 36.6969 32.5305C37.0908 32.1341 37.3737 31.641 37.5171 31.1008C38.0478 29.108 38.0478 24.9503 38.0478 24.9503C38.0478 24.9503 38.0478 20.7926 37.5171 18.8002Z"
      fill="white"
    />
    <path d="M23.0121 28.3549L28.9971 24.9503L23.0121 21.5457V28.3549Z" fill="#009393" />
  </svg>
)
export default YoutubeIcon
