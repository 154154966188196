const FacebookIcon = () => (
  <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.3728 50.5962C39.1024 50.5962 50.2324 39.4661 50.2324 25.7365C50.2324 12.0068 39.1024 0.87677 25.3728 0.87677C11.6431 0.87677 0.513062 12.0068 0.513062 25.7365C0.513062 39.4661 11.6431 50.5962 25.3728 50.5962Z"
      fill="#009393"
    />
    <path
      d="M33.5823 27.9801V22.4201H28.1241V18.8256C28.0855 18.4107 28.1429 17.9927 28.292 17.6024C28.4411 17.2121 28.6781 16.8595 28.9855 16.5707C29.2929 16.2819 29.6628 16.0643 30.0678 15.9339C30.4729 15.8036 30.9027 15.7638 31.3256 15.8176H33.8045V11.0938C32.3342 10.8586 30.8478 10.7316 29.358 10.7137C24.9114 10.7137 21.9322 13.3743 21.9322 18.1958V22.4201H16.941V27.9801H22.0212V41.4022C24.0585 41.7062 26.1313 41.7062 28.1686 41.4022V27.9801H33.5823Z"
      fill="white"
    />
  </svg>
)

export default FacebookIcon
