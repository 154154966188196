const XIcon = (props) => (
  <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.392 50.5941C39.1205 50.5941 50.2496 39.465 50.2496 25.7365C50.2496 12.0081 39.1205 0.878937 25.392 0.878937C11.6636 0.878937 0.534485 12.0081 0.534485 25.7365C0.534485 39.465 11.6636 50.5941 25.392 50.5941Z"
      fill="#009393"
    />
    <mask
      id="mask0_3_386"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="13"
      y="13"
      width="26"
      height="26"
    >
      <path d="M13.2122 13.1325H38.1983V38.1186H13.2122V13.1325Z" fill="white" />
    </mask>
    <g mask="url(#mask0_3_386)">
      <path
        d="M32.8887 14.3033H36.7205L28.3502 23.8944L38.1983 36.9478H30.4883L24.4452 29.0326L17.5383 36.9478H13.703L22.6551 26.6857L13.2122 14.3051H21.1185L26.5726 21.5385L32.8887 14.3033ZM31.5413 34.6491H33.6651L19.9584 16.4824H17.6811L31.5413 34.6491Z"
        fill="white"
      />
    </g>
  </svg>
)

export default XIcon
